import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import "./login.css";

function Login() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("admin");
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/admin");
        }
    }, []);
    const onSubmit = async (data) => {
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu không hợp lệ"
            });
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
            .then((res) => {
                localStorage.setItem("admin", res.data.data);
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    navigate("/admin");
                });
            })
            .catch((err) => setErr("Username hoặc mật khẩu không chính xác"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <div className="header">ADMIN CP</div>
            <div className="app123">
                <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                    <h1>Đăng nhập quản trị</h1>
                    <div className="inputs">
                        <div>
                            <input
                                type="tel"
                                className="ip-lg"
                                {...register("username", { required: true })}
                                placeholder="Nhập username của bạn"
                            />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div>
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Nhập mật khẩu của bạn" />
                            <div onClick={toggleShowPassword} className="change-visible">
                                {showPassword ? <VisibilityOff sx={{ color: "#eee" }} /> : <Visibility sx={{ color: "#eee" }} />}
                            </div>
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                    </div>
                    {err ? <p style={{ color: "red" }}>{err}</p> : null}
                    <button className="btn-lg" type="submit">
                        Đăng nhập
                    </button>
                </form>
            </div>
        </>
    );
}
export default Login;
