import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useRef, useEffect, useState } from "react";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import swal from "sweetalert";

function Login() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
    }, []);
    const onSubmit = async (data) => {
        if (data.username != "administrator") {
            if (data.username.length < 10 || data.username.length > 12) {
                setError("username", {
                    type: "minLength",
                    message: "Số điện thoại không hợp lệ"
                });
                //return;
            }
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu không hợp lệ"
            });
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
            .then((res) => {
                localStorage.setItem("user", res.data.data);
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    navigate("/");
                });
            })
            .catch((err) => setErr("Số điện thoại hoặc mật khẩu không chính xác"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <div className="header">ACCOUNTING</div>
            <div className="logo-login">
                <img src={require("../../img/logo.png")} />
            </div>
            <div className="app123">
                <div className="bg"></div>

                <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                    <h1>Đăng nhập</h1>
                    <div className="inputs">
                        <div>
                            <div className="flag">
                                <img src={require("../../img/flag.png")} />
                                +84
                            </div>
                            <input
                                type="tel"
                                className="ip-lg"
                                {...register("username", { required: true })}
                                placeholder="Nhập số điện thoại của bạn"
                                style={{ paddingLeft: "90px" }}
                            />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div>
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Nhập mật khẩu của bạn" />
                            <div onClick={toggleShowPassword} className="change-visible">
                                {showPassword ? <VisibilityOff sx={{ color: "#eee" }} /> : <Visibility sx={{ color: "#eee" }} />}
                            </div>
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                    </div>
                    {err ? <p style={{ color: "red" }}>{err}</p> : null}
                    <p className="p-lg">
                        <Link className="a-lg" to="https://direct.lc.chat/16932936">
                            Bạn quên mật khẩu ? Hãy ấn vào đây
                        </Link>
                    </p>
                    <button className="btn-lg" type="submit">
                        Đăng nhập
                    </button>
                    <button className="btn-lg" type="button" style={{ marginTop: "10px" }} onClick={() => navigate("/register")}>
                        Bạn chưa có tài khoản ? Đăng ký ngay
                    </button>
                </form>
            </div>
            <div className="bg-bot">
                <img src={require("../../img/bg_bot.png")} />
            </div>
        </>
    );
}
export default Login;
